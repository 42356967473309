import { FC } from 'react'
import { SectionCaseStudyHeroProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Container, Media, Pill, SectionWrapper } from '@portfolio/components'

const StyledSectionWrapper = styled(SectionWrapper, {
  position: 'relative',
  display: 'grid',
  height: '75vh',
  '@lg': {
    height: '110vh',
  },
})

const ContainerStyled = styled(Container, {
  marginTop: '$32',
  marginBottom: '$32',
})

const ContentWrap = styled(Container, {
  gridColumn: 1,
  gridRow: 1,
  display: 'grid',
  gap: '$40',
  zIndex: '$content',
  color: '$white',
  position: 'sticky',
  bottom: '$48',
  justifySelf: 'center',
  alignSelf: 'flex-end',
  justifyItems: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '$56 0',
  '@lg': {
    margin: '$96 0',
    bottom: '$96',
  },
})
const Title = styled('h1', {
  projectFont: 'heading03',
  textTransform: 'uppercase',
})
const ShortDescription = styled('h2', {
  projectFont: 'heading01',
  textAlign: 'center',
  maxWidth: 1100,
})

const Services = styled('div', {
  listStyleType: 'none',
  flexWrap: 'wrap',
  gap: 10,
  listStyle: 'none',
  variants: {
    device: {
      desktop: {
        display: 'none',
        '@md': {
          display: 'flex',
        },
      },
      mobile: {
        display: 'flex',
        '@md': {
          display: 'none',
        },
      },
    },
  },
})
const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
})
const InnerWrap = styled('div', {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
})

export const SectionCaseStudyHero: FC<SectionCaseStudyHeroProps> = ({
  image,
  name,
  shortDescription,
  priority,
  services,
}) => {
  return (
    <>
      <StyledSectionWrapper type="NO_MARGIN">
        {image && (
          <MediaWrap>
            <InnerWrap>
              <Media
                {...image}
                hardcropRatio={'landscape1'}
                layout={'fill'}
                priority={priority}
                controlsPosition="bottom-right"
              />
            </InnerWrap>
          </MediaWrap>
        )}
        <ContentWrap>
          {name && <Title>{name}</Title>}
          {shortDescription && (
            <ShortDescription>{shortDescription}</ShortDescription>
          )}
          {services && (
            <Services device={'desktop'}>
              {services.map((s, i) => (
                <Pill as="h3" appearance="UI05" backdrop key={i}>
                  {s}
                </Pill>
              ))}
            </Services>
          )}
        </ContentWrap>
      </StyledSectionWrapper>
      {services && (
        <ContainerStyled>
          <Services as={'ul'} device={'mobile'}>
            {services.map((s, i) => (
              <li key={i}>
                <Pill appearance="UI05Black">{s}</Pill>
              </li>
            ))}
          </Services>
        </ContainerStyled>
      )}
    </>
  )
}
