import { FC } from 'react'
import { SectionSingleMediaWithDescriptionProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import {
  Media,
  MediaWithBackground,
  SectionWrapper,
} from '@portfolio/components'

const StyledSectionWrapper = styled(SectionWrapper, {
  display: 'grid',
  rowGap: '$20',
})

const Description = styled('p', {
  projectFont: 'body04',
  color: '$black_06',
  '@lg': {
    maxWidth: '376px',
  },
})
const MediaWrap = styled('div', {
  variants: {
    mode: {
      'full-width': {
        '@lg': {
          containerSpace: 'medium',
        },
      },
      'with-margins': {
        containerSpace: 'medium',
      },
      inline: {},
    },
  },
})
const DescriptionWrap = styled('div', {
  variants: {
    mode: {
      'full-width': {
        containerSpace: 'medium',
      },
      'with-margins': {
        containerSpace: 'medium',
      },
      inline: {},
    },
  },
})
export const SectionSingleMediaWithDescription: FC<
  SectionSingleMediaWithDescriptionProps
> = ({ mode = 'full-width', mediaField, mediaDescription, priority }) => {
  if (!mediaField?.media) {
    return null
  }

  return (
    <StyledSectionWrapper
      type={mode === 'full-width' ? 'RICH_TEXT_LG' : 'RICH_TEXT_SM'}
    >
      <MediaWrap mode={mode}>
        {mediaField._type === 'simpleMedia' ? (
          <Media {...mediaField.media} priority={priority} />
        ) : (
          <MediaWithBackground
            media={mediaField.media}
            color={mediaField.color}
            mediaBorder={mediaField.mediaBorder}
            priority={priority}
            appearance="singleMedia"
          />
        )}
      </MediaWrap>
      {mediaDescription && (
        <DescriptionWrap mode={mode}>
          <Description>↑ {mediaDescription}</Description>
        </DescriptionWrap>
      )}
    </StyledSectionWrapper>
  )
}
