import { FC } from 'react'
import { SectionIconWithTextListProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Media, SectionWrapper } from '@portfolio/components'

const StyledSectionWrapper = styled(SectionWrapper, {
  '& > div:last-child': {
    borderBottom: '1px solid $gray500_01',
  },
})

const ItemWrap = styled('div', {
  padding: '$12 0 $20',
  borderTop: '1px solid $gray500_01',
  '@md': {
    padding: '$16 0 $24',
    display: 'grid',
    justifyContent: 'space-between',
    gap: '$48',
    alignItems: 'center',
  },
})

const Title = styled('p', {
  projectFont: 'ui03',
  textTransform: 'uppercase',
  marginBottom: '0 !important',

  '@md': {
    gridColumn: '1/3',
    gridRow: 1,
  },
})

const Text = styled('p', {
  projectFont: 'body04',
  marginTop: '0 !important',

  '@md': {
    gridColumn: 2,
    gridRow: 2,
    width: 330,
  },
})

const IconWrap = styled('div', {
  width: '80px',
  paddingBottom: '$8',
  paddingTop: '$24',

  '@md': {
    paddingBottom: 0,
    paddingTop: 0,
    gridColumn: 1,
    gridRow: 2,
  },
})

export const SectionIconWithTextList: FC<SectionIconWithTextListProps> = ({
  items,
}) => {
  return (
    <StyledSectionWrapper type="RICH_TEXT_SM">
      {items?.map((item, index) => (
        <ItemWrap key={`${index}-${item.title}`}>
          <Title>{item?.title}</Title>
          <IconWrap>
            {item?.icon && (
              <Media {...item?.icon} layout="responsive" sizes="80px" />
            )}
          </IconWrap>
          <Text>{item?.text}</Text>
        </ItemWrap>
      ))}
    </StyledSectionWrapper>
  )
}
