import { FC } from 'react'
import { SectionQuoteProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { SectionWrapper } from '@portfolio/components'

const StyledSectionWrapper = styled(SectionWrapper, {
  '@lg': {
    padding: '0 $20',
  },
})

const Quote = styled('p', {
  projectFont: 'heading06',
  marginTop: '$96',
  textAlign: 'center',
})

const Author = styled('p', {
  textAlign: 'center',
  projectFont: 'body03',
})

export const SectionQuote: FC<SectionQuoteProps> = ({ quote, author }) => {
  return (
    <StyledSectionWrapper type="RICH_TEXT_MD">
      <Quote>{quote}</Quote>
      <Author>{author}</Author>
    </StyledSectionWrapper>
  )
}
