import { SectionServicesProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Container, Pill, SectionWrapper } from '@portfolio/components'
import { FC } from 'react'

const StyledSectionWrapper = styled(SectionWrapper, {
  color: '$white',
  display: 'grid',

  '@lg': {
    gap: 'unset',
    gridTemplateColumns: '1fr 2fr .25fr', // .25fr is so that text is not aligned to right edge on smaller viewports, it looks weird
  },
})

const ServicesListContainer = styled(Container, {
  display: 'grid',
  maxWidth: '840px',

  '@lg': {
    gridColumn: '2/span 1',
  },
})

const ServicesList = styled('ul', {
  listStyleType: 'none',
  display: 'grid',
  gap: '$72',

  '@lg': {
    gap: '$120',
  },
})

const ServicesListItem = styled('li', {
  display: 'grid',
  gap: '30px',
})

const Title = styled('h2', {
  projectFont: 'heading04',
  color: '$white',
})

const Description = styled('p', {
  projectFont: 'body03',
  color: '$white_07',
})

const PillsList = styled('ul', {
  display: 'flex',
  flexWrap: 'wrap',
  listStyleType: 'none',
})

const PillListItem = styled('li', {
  margin: '0 $8 $8 0',
})

export const SectionServices: FC<SectionServicesProps> = ({ services }) => {
  if (!services) return null

  return (
    <StyledSectionWrapper type="DEFAULT">
      <div />

      <ServicesListContainer>
        <ServicesList>
          {services?.map((serviceItem) => (
            <ServicesListItem key={serviceItem?._key}>
              {serviceItem?.title && <Title>{serviceItem?.title}</Title>}

              {serviceItem?.description && (
                <Description>{serviceItem?.description}</Description>
              )}

              {serviceItem?.pills?.length ? (
                <PillsList>
                  {serviceItem?.pills?.map((service, index) => (
                    <PillListItem key={`${service?.title}-${index}`}>
                      <Pill as="h3">{service?.title}</Pill>
                    </PillListItem>
                  ))}
                </PillsList>
              ) : null}
            </ServicesListItem>
          ))}
        </ServicesList>
      </ServicesListContainer>

      <div />
    </StyledSectionWrapper>
  )
}
