import { FC, useEffect, useRef, useState } from 'react'
import { SectionIntroTextsProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Container, SectionWrapper } from '@portfolio/components'

const TEXT_WRAPPER_HEIGHT = 40
const CALCULATED_PADDING = 50 - TEXT_WRAPPER_HEIGHT / 2
const ANIMATION_ROOT_MARGIN = 50 - TEXT_WRAPPER_HEIGHT

const StyledSectionWrapper = styled(SectionWrapper, {
  background: '$darkThemeBackground',
  paddingBottom: '$120',
  color: '$white',
  paddingTop: `${CALCULATED_PADDING}vh`,
})

const TextWrap = styled(Container, {
  maxWidth: '$aboutPageContentWidth',
  margin: '0 auto',
  minHeight: `${TEXT_WRAPPER_HEIGHT}vh`,
  projectFont: 'heading02',
  transition: 'opacity 1s, transform 1s',
  display: 'grid',
  alignContent: 'center',
  variants: {
    isVisible: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0.1,
      },
    },
  },
})

type TextProps = {
  text: string
}
const Text: FC<TextProps> = ({ text }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!ref.current) return

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0]
        if (entry.isIntersecting) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      },
      {
        root: null,
        rootMargin: `-${ANIMATION_ROOT_MARGIN}% 0px -${ANIMATION_ROOT_MARGIN}% 0px`,
        threshold: 1,
      },
    )

    observer.observe(ref.current)

    return () => {
      ref.current && observer.unobserve(ref.current)
    }
  }, [ref])
  return (
    <TextWrap ref={ref} isVisible={isVisible}>
      {text}
    </TextWrap>
  )
}
export const SectionIntroTexts: FC<SectionIntroTextsProps> = ({ texts }) => {
  return (
    <StyledSectionWrapper type="NO_MARGIN">
      {texts?.map((text, index) => (
        <Text key={`intro-text-${index}`} text={text} />
      ))}
    </StyledSectionWrapper>
  )
}
