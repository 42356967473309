import { FC, useEffect, useState } from 'react'
import { SectionHomeHeroProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Media, SectionWrapper } from '@portfolio/components'

const Text = styled('h1', {
  textAlign: 'center',
  projectFont: 'display02',
  maxWidth: '$homeHeroTextWidth',
  margin: '0 auto',
  variants: {
    textTheme: {
      light: {
        color: '$white',
      },
      dark: {
        color: '$black',
      },
    },
  },
})

const StyledSectionWrapper = styled(SectionWrapper, {
  display: 'grid',
  gap: '$72',
  containerSpace: 'medium',
  marginBottom: '$mobileSectionMarginL',
  '@lg': {
    marginBottom: '$desktopSectionMarginL',
  },

  '@xxl': {
    padding: '$48 0', // extra padding for large desktops
  },
})

const LogosWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$32',
  margin: '0 auto',

  '@sm': {
    gap: '$40',
  },

  '@md': {
    gap: '$48',
  },
})

const Logos = styled('div', {
  position: 'relative',
  width: 76,
  '@md': {
    width: '$96',
  },
  height: '$36',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Logo = styled('span', {
  transition: `opacity .2s ease-in-out, transform .3s , visibility .3s `,
  position: 'absolute',
  left: '0',
  right: '0',

  variants: {
    status: {
      active: {
        opacity: '1',
        transform: 'translateY(0)',
        visibility: 'visible',
      },

      hidden: {
        opacity: '0',
        transform: 'translateY(-20px)',
        visibility: 'hidden',
      },
    },
  },
})

export const SectionHomeHero: FC<SectionHomeHeroProps> = ({
  text,
  technologiesRows,
  textTheme = 'dark',
  priority,
}) => {
  const TIMEOUT_ANIMATION = 4000
  const DELAY_MULTIPLIER = 150

  const [counter, setCounter] = useState(0)

  const itemsArray =
    technologiesRows && technologiesRows[0].items
      ? [...new Array(technologiesRows[0]?.items.length)]
      : []

  useEffect(() => {
    const rowsNum = technologiesRows?.length
    if (!rowsNum) return setCounter(0)

    const interval = setInterval(() => {
      setCounter((val) => (val < rowsNum - 1 ? val + 1 : 0))
    }, TIMEOUT_ANIMATION)

    return () => clearInterval(interval)
  }, [])

  return (
    <StyledSectionWrapper type="FIRST_SECTION">
      {text && <Text textTheme={textTheme}>{text}</Text>}
      <LogosWrap>
        {itemsArray.map((_, index) => {
          return (
            <Logos key={`logos-column-${index}`}>
              {technologiesRows?.map((el, i) => {
                const isActive = i === counter
                return (
                  el.items && (
                    <Logo
                      status={isActive ? 'active' : `hidden`}
                      key={`${el.items[index].alt}-${i}`}
                      style={{
                        transitionDelay: `${
                          index * DELAY_MULTIPLIER + (isActive ? 100 : 0)
                        }ms`,
                      }}
                    >
                      {el.items[index].mediaPayload && (
                        <Media
                          {...el.items[index]}
                          priority={priority && i === 0}
                          sizes="80px"
                          disablePlaceholderBackground
                        />
                      )}
                    </Logo>
                  )
                )
              })}
            </Logos>
          )
        })}
      </LogosWrap>
    </StyledSectionWrapper>
  )
}
