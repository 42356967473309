import { FC } from 'react'
import { SectionProjectCardProps } from '@portfolio/models'
import {
  Container,
  Link,
  Media,
  Pill,
  SectionWrapper,
} from '@portfolio/components'
import { styled, useMediaQuery } from '@portfolio/styles'

const Wrap = styled(SectionWrapper, {
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gap: '$32',
  '@lg': {
    gridTemplateRows: '1fr 400px',
    gap: '$32',
  },
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  '@lg': {
    gridColumn: 1,
    gridRow: '1 / 3',
  },
})

const ContentWrap = styled(Container, {
  color: '$darkThemeBackground',
  display: 'grid',
  gap: '$24',
  justifyItems: 'flex-start',
  gridRow: 2,
  gridColumn: 1,
  pointerEvents: 'none',
  '@lg': {
    background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3))',
    zIndex: '$content',
    alignContent: 'flex-end',
    gridTemplateColumns: '480px auto',
    justifyContent: 'space-between',
    paddingBottom: '$40',
    color: '$white',
  },
})
const Description = styled('h2', {
  projectFont: 'heading07',
  color: '$white_07',
  zIndex: '$content',
})
const CaseStudyName = styled('span', {
  color: '$white',
  zIndex: '$content',
})

const LinkWrap = styled(Link, {
  gridColumn: 1,
  gridRow: '1 / -1',
  zIndex: '$content',
  '&:focus-visible': {
    outline: '$focusBlue solid 5px;',
    outlineOffset: '-5px',
  },
})

const Services = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
  listStyle: 'none',
  '@lg': {
    alignSelf: 'end',
    justifyContent: 'end',
  },
})

export const SectionProjectCard: FC<SectionProjectCardProps> = ({
  text,
  caseStudy,
  media,
  priority,
}) => {
  const isLg = useMediaQuery('lg')

  return (
    <Wrap type="CARD">
      {media && (
        <MediaWrap>
          <Media
            controlsPosition={isLg ? 'top-right' : 'bottom-right'}
            {...media}
            loop
            priority={priority}
          />
        </MediaWrap>
      )}
      {caseStudy && (
        <ContentWrap spaceSize={'small'}>
          {text && (
            <Description>
              <CaseStudyName>{caseStudy.name}</CaseStudyName> {text}
            </Description>
          )}
          {caseStudy.services && (
            <Services>
              {caseStudy.services.map((s, i) => (
                <Pill as="h3" appearance="UI05" backdrop key={i}>
                  {s}
                </Pill>
              ))}
            </Services>
          )}
        </ContentWrap>
      )}
      <LinkWrap
        href={caseStudy.path}
        appearance="grid"
        label={`Case Study - ${caseStudy.name}`}
      />
    </Wrap>
  )
}
