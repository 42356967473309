import { FC } from 'react'
import { SectionContactProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { CopyButton, SectionWrapper, Socials } from '@portfolio/components'
import { useTranslation } from 'react-i18next'

const StyledContainer = styled(SectionWrapper, {
  marginTop: '$168',
  containerSpace: 'medium',
})

const ContactWrap = styled('div', {
  '@md': {
    width: 'max-content',
    margin: '0 auto',
  },
})

const Title = styled('h1', {
  projectFont: 'display01',
  marginBottom: '$120',
  textAlign: 'center',
})

const Contact = styled('div', {
  padding: '$28 0 $32',
  borderTop: '1px solid $gray500_01',
})

const ContactTitle = styled('h2', {
  projectFont: 'heading08',
  marginBottom: '$8',
})

const ContactMail = styled('div', {
  projectFont: 'body03',
  display: 'flex',
  justifyContent: 'space-between',
})

const SocialsWrap = styled('div', {
  padding: '$28 0 $32',
  borderTop: '1px solid $gray500_01',
})

const SocialsTitle = styled('h2', {
  projectFont: 'heading08',
  marginBottom: '$16',
})

export const SectionContact: FC<SectionContactProps> = ({
  title,
  contacts,
  socials,
  priority,
}) => {
  const { t } = useTranslation('contactPage')

  return (
    <StyledContainer>
      <ContactWrap>
        {title && <Title>{title}</Title>}
        {contacts &&
          contacts.map((contact, index) => (
            <Contact key={index}>
              {contact.title && <ContactTitle>{contact.title}</ContactTitle>}
              <ContactMail>
                {contact.contact?.email}
                {contact.contact?.email && (
                  <CopyButton
                    copyText={contact.contact?.email}
                    theme="dark"
                    context={`ContactPage - ${contact.title}`}
                  />
                )}
              </ContactMail>
            </Contact>
          ))}
        {socials && (
          <SocialsWrap>
            <SocialsTitle>{t('followUs')}</SocialsTitle>
            <Socials
              items={socials.items}
              iconsColor="dark"
              priority={priority}
            />
          </SocialsWrap>
        )}
      </ContactWrap>
    </StyledContainer>
  )
}
