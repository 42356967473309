import { FC } from 'react'
import { SectionArticlesLandingPageProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Container, Link, Media, SectionWrapper } from '@portfolio/components'
import { useTranslation } from 'react-i18next'

const StyledSectionWrapper = styled(SectionWrapper, {
  marginBottom: '$mobileSectionMarginL',
  '@lg': {
    marginBottom: '$desktopSectionMarginL',
  },
})

const Title = styled('h1', {
  projectFont: 'heading01',
  textAlign: 'center',
  marginBottom: '$120',

  '@md': {
    marginBottom: '$168',
  },
})

const ArticlesGrid = styled('div', {
  maxWidth: '800px',
  margin: '0 auto',
  display: 'grid',
  gap: '$64',
})

const Article = styled('div', {
  position: 'relative',
  display: 'grid',
  gap: '$32',
  paddingBottom: '$64',
  borderBottom: '1px solid $black_01',

  '@md': {
    gridTemplateColumns: 'auto 240px',
    columnGap: '$40',
    rowGap: '$24',
  },
})

const ArticleTitle = styled('h2', {
  projectFont: 'heading06',
  gridRow: 1,
  gridColumn: 1,
})

const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 2,
  '@md': {
    gridRow: '1 / 3',
    gridColumn: '2 / 3',
  },
})

const StyledLink = styled(Link, {
  zIndex: '$content',
  projectFont: 'heading08',
  textDecoration: 'underline',
  gridColumn: 1,
  gridRow: 3,
  '@md': {
    gridRow: 2,
  },
})

const LinkWrap = styled(Link, {
  gridColumn: 1,
  gridRow: '1 / span 3',
  '@md': {
    gridColumn: '1 / span 2',
    gridRow: '1 / span 2',
  },
})

export const SectionArticlesLandingPage: FC<
  SectionArticlesLandingPageProps
> = ({ title, articles, priority }) => {
  const { t } = useTranslation('articles')

  return (
    <StyledSectionWrapper type="FIRST_SECTION">
      <Container>
        {title && <Title>{title}</Title>}
        <ArticlesGrid>
          {articles &&
            articles.map((article, index) => (
              <Article key={`${index}-${article.title}`}>
                {article.title && <ArticleTitle>{article.title}</ArticleTitle>}

                {article.image && (
                  <MediaWrap>
                    <Media
                      {...article.image}
                      hardcropRatio="landscape3"
                      priority={priority && index < 3}
                      sizes="(min-width: 740px) 240px, 100vw"
                    />
                  </MediaWrap>
                )}

                <StyledLink href={article.path} appearance="text">
                  {t('readArticle')}
                </StyledLink>

                <LinkWrap
                  href={article.path}
                  tabIndex={-1}
                  label={`Article - ${article.title}`}
                />
              </Article>
            ))}
        </ArticlesGrid>
      </Container>
    </StyledSectionWrapper>
  )
}
