import { FC, useEffect, useRef } from 'react'
import { SectionArticleDetailsProps } from '@portfolio/models'
import { Container, PortableText, SectionWrapper } from '@portfolio/components'
import { styled } from '@portfolio/styles'
import { useArticleContext } from '@portfolio/article'
import { useTranslation } from 'react-i18next'
import { SectionQuote } from '../SectionQuote/SectionQuote'
import { SectionIconWithTextList } from '../SectionIconWithTextList/SectionIconWithTextList'
import { SectionSingleMediaWithDescription } from '../SectionSingleMediaWithDescription/SectionSingleMediaWithDescription'

const StyledSectionWrapper = styled(SectionWrapper, {
  display: 'grid',
  gap: '$32',
  marginBottom: '$24',
  '@lg': {
    gap: 0,
    gridTemplateColumns: '1fr 2fr .25fr',
    marginBottom: '$48',
  },
})

const TagWrap = styled(Container, {
  display: 'grid',
  alignContent: 'flex-start',
})

const ContentWrap = styled(Container, {
  display: 'grid',
  gap: '$16',
  '@lg': {
    gridColumn: '2/span 1',
    maxWidth: '$containerMaxWidthForSidebarLayout',
    gap: '$32',
  },
})
const Title = styled('h1', {
  projectFont: 'heading09',
})

const Tag = styled('div', {
  projectFont: 'ui03',
  padding: '$4 $12',
  border: '1px solid',
  borderRadius: '$rMax',
  borderColor: '$black_05',
  color: '$black_08',
  textTransform: 'uppercase',
  width: 'max-content',
})

export const SectionArticleDetails: FC<SectionArticleDetailsProps> = ({
  title,
  introduction,
}) => {
  const { setVisibleChapterId } = useArticleContext()
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('article')

  useEffect(() => {
    if (!ref.current) return
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0]
        if (entry.isIntersecting && title) {
          setVisibleChapterId(title)
        }
      },
      {
        root: null,
        rootMargin: '0px 0px -90% 0px',
      },
    )

    observer.observe(ref.current)
  }, [title])
  return (
    <StyledSectionWrapper ref={ref} type="FIRST_SECTION">
      <TagWrap>
        <Tag>{t('insight')}</Tag>
      </TagWrap>
      <ContentWrap>
        {title && <Title>{title}</Title>}
        <PortableText
          value={introduction}
          // this section has explicit top and bottom margins so we disable them on PortableText. Section is display: grid, so margin collapsing doesn't work anyway.
          disableTopBottomMargins={true}
          additionalComponents={{
            types: {
              sectionQuote: (props) => {
                return <SectionQuote {...props.value} />
              },
              sectionIconWithTextList: (props) => {
                return <SectionIconWithTextList {...props.value} />
              },
              sectionSingleMediaWithDescription: (props) => {
                return <SectionSingleMediaWithDescription {...props.value} />
              },
            },
          }}
        />
      </ContentWrap>
    </StyledSectionWrapper>
  )
}
