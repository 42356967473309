import { FC } from 'react'
import { styled } from '@portfolio/styles'
import { SectionProjectCardGridProps } from '@portfolio/models'
import { Container, Link, Media, SectionWrapper } from '@portfolio/components'
import { useTranslation } from 'react-i18next'

const StyledSectionWrapper = styled(SectionWrapper, {
  backgroundColor: '$darkThemeBackground',
  paddingTop: 'calc($headerHeight + $mobileSectionMarginL)',
  paddingBottom: '$mobileSectionMarginL',
  color: '$white',
  display: 'grid',
  gap: '$96',
  '@md': {
    gap: '$120',
    paddingTop: 'calc($headerHeight + $desktopSectionMarginL)',
    paddingBottom: '$desktopSectionMarginL',
  },
})

const Title = styled('h2', {
  projectFont: 'heading04',
  containerSpace: 'small',
  textAlign: 'center',
})
const CardLink = styled(Link, {
  pointerEvents: 'all',
  zIndex: '$cardButton',
})
const ProjectsGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: '$72',
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
    rowGap: '$168',
    columnGap: '1px',
  },
})
const ProjectCardWrap = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gap: '$32',
  [`& ${CardLink}`]: {
    opacity: 1,
  },
  '@lg': {
    gap: '$40',
    [`& ${CardLink}`]: {
      opacity: 0,
      transition: '250ms opacity',
    },
    '&:hover': {
      [`& ${CardLink}`]: {
        opacity: 1,
        transition: '250ms opacity',
      },
    },
  },
})

const ContentWrap = styled(Container, {
  display: 'grid',
  gap: '$32',
  justifyItems: 'flex-start',
  color: '$white',
  gridColumn: 1,
  gridRow: 2,
  pointerEvents: 'none',
  '@lg': {
    alignContent: 'flex-start',
    gridTemplateColumns: '1fr auto',
    justifyContent: 'space-between',
  },
})
const Description = styled('h2', {
  projectFont: 'heading07',
  color: '$white_07',
  maxWidth: 480,
  zIndex: '$content',
})
const CaseStudyName = styled('span', {
  color: '$white',
  zIndex: '$content',
})

const LinkWrap = styled(Link, {
  gridColumn: 1,
  gridRow: '1 / -1',
  zIndex: '$content',
})
const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
})

export const SectionProjectCardGrid: FC<SectionProjectCardGridProps> = ({
  title,
  projects,
  priority,
}) => {
  const { t } = useTranslation('caseStudy')

  return (
    <StyledSectionWrapper type="NO_MARGIN">
      {title && <Title>{title}</Title>}
      <ProjectsGrid>
        {projects?.map((project, index) => (
          <ProjectCardWrap key={`card-${project.caseStudy?.name}-${index}`}>
            <MediaWrap>
              {project.media && (
                <Media
                  controlsPosition={'bottom-right'}
                  {...project.media}
                  loop
                  priority={priority && index < 4}
                  sizes="(min-width: 1000px) 50vw, 100vw"
                />
              )}
            </MediaWrap>
            <ContentWrap spaceSize={'small'}>
              {project.text && (
                <Description>
                  <CaseStudyName>{project?.caseStudy?.name}</CaseStudyName>{' '}
                  {project.text}
                </Description>
              )}
              <CardLink
                appearance={'outlinedWhite'}
                href={project?.caseStudy?.path}
              >
                {t('viewCaseStudy')}
              </CardLink>
            </ContentWrap>
            <LinkWrap
              href={project?.caseStudy?.path}
              appearance="grid"
              label={`Case Study - ${project?.caseStudy?.name}`}
            />
          </ProjectCardWrap>
        ))}
      </ProjectsGrid>
    </StyledSectionWrapper>
  )
}
