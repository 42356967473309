import { FC } from 'react'
import dynamic from 'next/dynamic'

import { PageBuilderSection } from '@portfolio/models'
import { SectionIntroTexts } from './SectionIntroTexts/SectionIntroTexts'
import { SectionArticlesLandingPage } from './SectionArticlesLandingPage/SectionArticlesLandingPage'
import { SectionArticleDetails } from './SectionArticleDetails/SectionArticleDetails'
import { SectionCaseStudyHero } from './SectionCaseStudyHero/SectionCaseStudyHero'
import { SectionProjectCardGrid } from './SectionProjectCardGrid/SectionProjectCardGrid'
import { SectionHomeHero } from './SectionHomeHero/SectionHomeHero'
import { SectionProjectCard } from './SectionProjectCard/SectionProjectCard'
import { SectionContact } from './SectionContact/SectionContact'
import { SectionSideTextBlock } from './SectionSideTextBlock/SectionSideTextBlock'
import { SectionServices } from './SectionServices/SectionServices'
import { SectionArticleContents } from './SectionArticleContents/SectionArticleContents'

// @ts-ignore
const DynamicSectionEditorialRichText = dynamic(() =>
  import('./SectionEditorialRichText/SectionEditorialRichText').then(
    (mod) => mod.SectionEditorialRichText,
  ),
)

// @ts-ignore
const DynamicSectionQuote = dynamic(() =>
  import('./SectionQuote/SectionQuote').then((mod) => mod.SectionQuote),
)

// @ts-ignore
const DynamicSectionCardSlider = dynamic(() =>
  import('./SectionCardSlider/SectionCardSlider').then(
    (mod) => mod.SectionCardSlider,
  ),
)

// @ts-ignore
const DynamicSectionNextProject = dynamic(() =>
  import('./SectionNextProject/SectionNextProject').then(
    (mod) => mod.SectionNextProject,
  ),
)

// @ts-ignore
const DynamicSectionCaseStudyDetails = dynamic(() =>
  import('./SectionCaseStudyDetails/SectionCaseStudyDetails').then(
    (mod) => mod.SectionCaseStudyDetails,
  ),
)

// @ts-ignore
const DynamicSectionTextBlockWithLink = dynamic(() =>
  import('./SectionTextBlockWithLink/SectionTextBlockWithLink').then(
    (mod) => mod.SectionTextBlockWithLink,
  ),
)

// @ts-ignore
const DynamicSectionTextBlocks = dynamic(() =>
  import('./SectionTextBlocks/SectionTextBlocks').then(
    (mod) => mod.SectionTextBlocks,
  ),
)

// @ts-ignore
const DynamicSectionVideoTestimonials = dynamic(() =>
  import('./SectionVideoTestimonials/SectionVideoTestimonials').then(
    (mod) => mod.SectionVideoTestimonials,
  ),
)

// @ts-ignore
const DynamicSectionSingleMediaWithDescription = dynamic(() =>
  import(
    './SectionSingleMediaWithDescription/SectionSingleMediaWithDescription'
  ).then((mod) => mod.SectionSingleMediaWithDescription),
)

// @ts-ignore
const DynamicSectionArticleChapter = dynamic(() =>
  import('./SectionArticleChapter/SectionArticleChapter').then(
    (mod) => mod.SectionArticleChapter,
  ),
)

// @ts-ignore
const DynamicSectionArticleAuthor = dynamic(() =>
  import('./SectionArticleAuthor/SectionArticleAuthor').then(
    (mod) => mod.SectionArticleAuthor,
  ),
)

// @ts-ignore
const DynamicSectionDoubleMediaWithBackground = dynamic(() =>
  import(
    './SectionDoubleMediaWithBackground/SectionDoubleMediaWithBackground'
  ).then((mod) => mod.SectionDoubleMediaWithBackground),
)

// @ts-ignore
const DynamicSectionCaseStudyCards = dynamic(() =>
  import('./SectionCaseStudyCards/SectionCaseStudyCards').then(
    (mod) => mod.SectionCaseStudyCards,
  ),
)

// @ts-ignore
const DynamicSectionServicesListing = dynamic(() =>
  import('./SectionServicesListing/SectionServicesListing').then(
    (mod) => mod.SectionServicesListing,
  ),
)

// @ts-ignore
const DynamicSectionTechstackSlider = dynamic(() =>
  import('./SectionTechstackSlider/SectionTechstackSlider').then(
    (mod) => mod.SectionTechstackSlider,
  ),
)

// @ts-ignore
const DynamicSectionNewsletter = dynamic(() =>
  import('./SectionNewsletter/SectionNewsletter').then(
    (mod) => mod.SectionNewsletter,
  ),
)

// @ts-ignore
const DynamicSectionSocialMediaEmbed = dynamic(() =>
  import('./SectionSocialMediaEmbed/SectionSocialMediaEmbed').then(
    (mod) => mod.SectionSocialMediaEmbed,
  ),
)

// @ts-ignore
const DynamicSectionFeaturedBlogPosts = dynamic(() =>
  import('./SectionFeaturedBlogPosts/SectionFeaturedBlogPosts').then(
    (mod) => mod.SectionFeaturedBlogPosts,
  ),
)

// @ts-ignore
const DynamicSectionTermly = dynamic(() =>
  import('./SectionTermly/SectionTermly').then((mod) => mod.SectionTermly),
)

export type PageBuilderProps = {
  section: PageBuilderSection
}

export const PageBuilder: FC<PageBuilderProps> = ({ section }) => {
  /**
   * We use two section import strategies:
   * - Dynamic - in order to minimise the size of bundle chunks, we prefer to import sections dynamically
   * - Static - to prevent the "blink" effect in most exposed sections we use static imports
   */
  switch (section._type) {
    // dynamic sections ======================================
    case 'sectionTermly':
      return <DynamicSectionTermly {...section} />

    case 'sectionFeaturedBlogPosts':
      return <DynamicSectionFeaturedBlogPosts {...section} />

    case 'sectionSocialMediaEmbed':
      return <DynamicSectionSocialMediaEmbed {...section} />

    case 'sectionNewsletter':
      return <DynamicSectionNewsletter {...section} />

    case 'sectionDoubleMediaWithBackground':
      return <DynamicSectionDoubleMediaWithBackground {...section} />

    case 'sectionArticleAuthor':
      return <DynamicSectionArticleAuthor {...section} />

    case 'sectionArticleChapter':
      return <DynamicSectionArticleChapter {...section} />

    case 'sectionSingleMediaWithDescription':
      return <DynamicSectionSingleMediaWithDescription {...section} />

    case 'sectionVideoTestimonials':
      return <DynamicSectionVideoTestimonials {...section} />

    case 'sectionTextBlocks':
      return <DynamicSectionTextBlocks {...section} />

    case 'sectionTextBlockWithLink':
      return <DynamicSectionTextBlockWithLink {...section} />

    case 'sectionCaseStudyDetails':
      return <DynamicSectionCaseStudyDetails {...section} />

    case 'sectionNextProject':
      return <DynamicSectionNextProject {...section} />

    case 'sectionCardSlider':
      return <DynamicSectionCardSlider {...section} />

    case 'sectionQuote':
      return <DynamicSectionQuote {...section} />

    case 'sectionEditorialRichText':
      return <DynamicSectionEditorialRichText {...section} />

    case 'sectionCaseStudyCards':
      return <DynamicSectionCaseStudyCards {...section} />

    case 'sectionServicesListing':
      return <DynamicSectionServicesListing {...section} />

    case 'sectionTechstackSlider':
      return <DynamicSectionTechstackSlider {...section} />

    // static sections ==================================
    case 'sectionIntroTexts':
      return <SectionIntroTexts {...section} />

    case 'sectionCaseStudyHero':
      return <SectionCaseStudyHero {...section} />

    case 'sectionProjectCardGrid':
      return <SectionProjectCardGrid {...section} />

    case 'sectionHomeHero':
      return <SectionHomeHero {...section} />

    case 'sectionProjectCard':
      return <SectionProjectCard {...section} />

    case 'sectionArticleDetails':
      return <SectionArticleDetails {...section} />

    case 'sectionArticlesLandingPage':
      return <SectionArticlesLandingPage {...section} />

    case 'sectionContact':
      return <SectionContact {...section} />

    case 'sectionSideTextBlock':
      return <SectionSideTextBlock {...section} />

    case 'sectionServices':
      return <SectionServices {...section} />

    case 'sectionArticleContents':
      return <SectionArticleContents {...section} />

    default:
      return null
  }
}
