import { FC } from 'react'
import { SectionSideTextBlockProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { SectionWrapper } from '@portfolio/components'

const Content = styled('div', {
  display: 'grid',
  gap: '$36',
  margin: '0 auto',
  containerSpace: 'medium',
  width: '100%',
})

const Heading = styled('h2', {
  projectFont: 'heading07',
  maxWidth: '$sideTextBlockWidth',
  color: '$white_07',
})

const Text = styled('h3', {
  projectFont: 'heading02',
  maxWidth: '$sideTextBlockWidth',
  color: '$white',
})
export const SectionSideTextBlock: FC<SectionSideTextBlockProps> = ({
  text,
  heading,
  priority,
}) => {
  return (
    <SectionWrapper type={'FIRST_SECTION'}>
      <Content>
        {heading && <Heading as={priority ? 'h1' : 'h2'}>{heading}</Heading>}
        {text && <Text as="p">{text}</Text>}
      </Content>
    </SectionWrapper>
  )
}
