import { FC, useRef, useEffect, useCallback, useState } from 'react'
import { styled } from '@portfolio/styles'
import { useArticleContext } from '@portfolio/article'
import { SectionArticleContentsProps } from '@portfolio/models'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div', {
  display: 'none',
  position: 'sticky',
  background: '$white',
  top: -1,
  zIndex: '$header',
  marginBottom: '$24',
  '@lg': {
    display: 'block',
    position: 'relative',
    maxWidth: '360px', // tiny text looks bad when it's too wide
  },
})
const Chapter = styled('a', {
  projectFont: 'ui04',
  color: '$black_05',
  display: 'grid',
  gridTemplateColumns: '18px 1fr',
  whiteSpace: 'nowrap',
  variants: {
    active: {
      true: {
        color: '$black',
      },
    },
  },
  '@lg': {
    whiteSpace: 'unset',
  },
})
const ChapterIndex = styled('span', {
  marginRight: '$8',
})

const ChaptersWrap = styled('div', {
  display: 'flex',
  flexFlow: 'row',
  gap: '$24',
  paddingTop: '$24',
  paddingBottom: '$24',
  containerSpace: 'medium',
  '@lg': {
    flexDirection: 'column',
    gap: '$8',
    containerSpace: 'none',
    paddingTop: '$0',
    paddingBottom: '$0',
  },
})
const SliderWrap = styled('div', {
  overflowX: 'scroll',
  overflowY: 'auto',
  width: '100%',
  display: 'flex',

  scrollSnapType: 'x mandatory',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '@lg': {
    display: 'block',
    position: 'sticky',
    top: '$48',
    overflowX: 'unset',
    overflowY: 'unset',
    containerSpace: 'medium',
  },
  variants: {
    isSticky: {
      true: {
        boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.1)',

        '@lg': {
          boxShadow: 'none',
        },
      },
    },
  },
})
const ContentsTitle = styled('h2', {
  projectFont: 'ui03',
  textTransform: 'uppercase',
  marginBottom: '$20',
  display: 'none',
  '@lg': {
    display: 'block',
  },
})
export const SectionArticleContents: FC<SectionArticleContentsProps> = ({
  contents,
}) => {
  const { t } = useTranslation('article')
  const { visibleChapterId } = useArticleContext()
  const MIN_SHIFT_LEFT = 20
  const [activeSlideScrollPos, setAtiveSlideScrollPos] = useState<
    number | undefined
  >(undefined)
  const [isSticky, setIsSticky] = useState<boolean>(false)

  const sliderRef = useRef<HTMLDivElement>(null)
  const sliderEl = sliderRef.current

  /*   Callback used every time when set active slide ref
  that set position to scroll when slide is active */
  const activeSlideRef = useCallback<(node: HTMLAnchorElement) => void>(
    (node) => {
      if (node !== null) {
        const { offsetLeft } = node
        const { width } = node.getBoundingClientRect()
        const windowWidth = window.innerWidth

        const shiftToCenter =
          (windowWidth - width) / 2 > 0
            ? (windowWidth - width) / 2
            : MIN_SHIFT_LEFT

        setAtiveSlideScrollPos(offsetLeft - shiftToCenter)
      }
    },
    [],
  )

  // Scroll to currently active chapter
  useEffect(() => {
    if (activeSlideScrollPos && sliderEl) {
      sliderEl.scroll({
        left: activeSlideScrollPos,
      })
    }
  }, [activeSlideScrollPos, sliderEl])

  // Check that element is sticky for shadow
  useEffect(() => {
    if (!sliderRef.current) return
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0]
        if (entry.intersectionRatio < 1) {
          setIsSticky(true)
        } else {
          setIsSticky(false)
        }
      },
      {
        root: null,
        threshold: [1],
      },
    )
    observer.observe(sliderRef.current)
  })

  // Add smooth scroll in that way because we want to have scroll behavior only for chapter links and not when user click link which navigates to the another page.
  const addSmoothScroll = () => {
    const html = document.querySelector('html')

    html?.style.setProperty('scroll-behavior', 'smooth')
    setTimeout(() => html?.style.removeProperty('scroll-behavior'), 0)
  }

  return (
    <Wrap>
      <SliderWrap ref={sliderRef} isSticky={isSticky}>
        <ContentsTitle>{t('contents')}</ContentsTitle>
        <ChaptersWrap>
          {contents?.map((chapter, index) => {
            return (
              <Chapter
                data-active={chapter.chapterTitle === visibleChapterId}
                active={chapter.chapterTitle === visibleChapterId}
                key={`${chapter.chapterTitle}-${index}`}
                href={`#${chapter.chapterTitle}`}
                onClick={addSmoothScroll}
                ref={
                  chapter.chapterTitle === visibleChapterId
                    ? activeSlideRef
                    : undefined
                }
              >
                <ChapterIndex>{index + 1}.</ChapterIndex>
                {chapter.chapterTitle}
              </Chapter>
            )
          })}
        </ChaptersWrap>
      </SliderWrap>
    </Wrap>
  )
}
